import React from "react"
import PropTypes from "prop-types"
import styled from "styled-components"
import { graphql, useStaticQuery } from "gatsby"

import colors from "../utils/colors"
import { outerStyles, innerStyles, Title, Subtitle, LinkToQuiz } from "../utils/common-styles"

const Container = styled.section`
  ${outerStyles}
  background: ${colors.BLUE};
`

const Inner = styled.div`
  ${innerStyles}
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  min-height: 400px;
  height: calc(100vh - 80px);
`

const Icon = styled.img`
  margin-bottom: 20px;
`

const StartQuizInfo = ({ quizType }) => {
  const { infoArray: { nodes } } = useStaticQuery(getInfoArray)
  const startInfo = nodes.find((info) => info.name === quizType);
  const {
    name,
    // image,
    title: {
      title,
    },
    subtitle: {
      subtitle,
    },
    linkToQuiz,
  } = startInfo

  // const StyledIcon = image ? (
  //   <Icon src={image.fluid.src} alt={name} />
  // ) : null

  const StyledLinkToQuiz = linkToQuiz ? (
    <>
      <LinkToQuiz to="/quiz/">Starta quizet</LinkToQuiz>
      <Subtitle>Ingen registrering krävs</Subtitle>
    </>
  ) : null

  // TODO: Get only one record, i.e.
  // contentfulStartInfo(name: {eq: "react"})
  // instead of getting all records and find what we need
  // look at the code below

  return (
    <Container>
      <Inner>
        {/* { StyledIcon } */}
        <Title>{ title }</Title>
        <Subtitle>{ subtitle }</Subtitle>
        { StyledLinkToQuiz }
      </Inner>
    </Container>
  )
}

const getInfoArray = graphql`
  {
    infoArray: allContentfulStartInfo {
      nodes {
        name
        title {
          title
        }
        subtitle {
          subtitle
        }
        linkToQuiz
      }
    }
  }
`

/*
const getInfo = (name) => graphql`
  {
    info: contentfulStartInfo(name: {eq: ${name}}) {
      image {
        fluid(maxHeight: 100) {
          src
        }
      }
      title {
        title
      }
      subtitle {
        subtitle
      }
      linkToQuiz
    }
  }
`
*/

StartQuizInfo.defaultProps = {
  quizType: "general",
}

StartQuizInfo.propTypes = {
  quizType: PropTypes.string,
}

export default StartQuizInfo
