import React from "react"
import SEO from "../components/seo"
import StartQuizInfo from "../components/start-quiz-info"
import HeaderQuiz from "../components/header-quiz"
import GlobalStyles from "../components/global-styles.js"
import "typeface-muli"
import ogImage from "../images/image_rekryterautvecklare.png"
import { Title, mobileWidth } from "../utils/common-styles"
import colors from "../utils/colors"
import styled from "styled-components"



import { graphql } from "gatsby"


import ResultList from "../components/quiz-result"

import LayoutQuiz from "../components/layout-quiz"
import Progress from "../components/progress"



const Answers = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  @media (max-width: ${mobileWidth}px) {
    flex-direction: column;
    align-items: flex-start;
    width: 75%;
  }
`

const Answer = styled.div`
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  align-items: center;
  color: ${colors.WHITE};
  cursor: pointer;
  /* margin-right: 10vw; */
  width: 240px;
  text-align: center;
  &:last-child {
    margin-right: 0;
  }
  @media (max-width: ${mobileWidth}px) {
    margin: 0 0 20px 0;
    justify-content: flex-start;
    flex-direction: row;
    max-width: fit-content;
  }
`

const AnswerImageContainer = styled.div`
  height: 150px;
  @media (max-width: ${mobileWidth}px) {
    height: fit-content;
    width: fit-content;
    margin-right: 20px;
  }
`

const AnswerImage = styled.img`
  display: block;
  margin: 0;
  @media (max-width: ${mobileWidth}px) {
    max-width: 10vw;
    max-height: 12vw;
  }
`

const AnswerText = styled.div`
  font-size: 14px;
  line-height: 18px;
  color: ${colors.WHITE};
  height: 2vw;
  max-width: 16vw;
  @media (max-width: ${mobileWidth}px) {
    font-size: 12px;
    line-height: 16px;
    height: fit-content;
    text-align: left;
    max-width: 50vw;
  }
`


const AnswersList = ({ answers, questionNumber, questionText, handleAnswer }) =>
  answers.map(({ id, title, file: { url }, description }) => (
    <Answer
      key={id}
      onClick={handleAnswer({
        question: {
          number: questionNumber,
          text: questionText,
        },
        answer: {
          number: Number(description),
          text: title,
        },
      })}
    >
      <AnswerImageContainer>
        <AnswerImage src={url} alt={title} />
      </AnswerImageContainer>
      <AnswerText>{title}</AnswerText>
    </Answer>
  ))



const QuizPage = ({
  data: {
    allContentfulQuizQuestion: { nodes },
  },
  handleAnswer,
  quizState,
}) => {
  if (!(nodes && nodes.length && quizState)) {
    return (
      <LayoutQuiz>
        <SEO title="Quiz" />
        <Title>Quiz not found</Title>
      </LayoutQuiz>
    )
  }

  const { currentQuestion } = quizState

  if (currentQuestion > nodes.length) {
    return <ResultList answers={quizState.answers} />
  }

  const { answers, question } = nodes.find(
    ({ number }) => number === currentQuestion
  )

  return (
    <LayoutQuiz>
      <SEO title="Quiz" />
      <Title>{question}</Title>
      <Answers>
        <AnswersList
          answers={answers}
          questionNumber={currentQuestion}
          questionText={question}
          handleAnswer={handleAnswer}
        />
      </Answers>
      <Progress total={nodes.length} current={currentQuestion} />
    </LayoutQuiz>
  )
}

export default QuizPage


export const pageQuery = graphql`
  query FiltereqQuizQuestionsPage {
    allContentfulQuizQuestion(filter: { quizType: { name: { eq: "general" } } }) {
      nodes {
        quizType {
          name
        }
        number
        question
        answers {
          id
          title
          file {
            url
          }
          description
        }
      }
    }
  }
`